<template>
  <div>
    <v-overlay :value="$store.state.overlay.show" :absolute="false">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-card flat>
      <v-col :key="search.id" v-for="search in searches" class="pa-0 pl-2" cols="auto">

        <v-row @mouseover="cIndex=search.id" @mouseleave="cIndex=-1" class="article_header hover-tab-highlight"
               no-gutters
               style="flex-wrap: nowrap;"
        >
          <v-col cols="11">
            <div class="article_title justify-space-between height-center" style="margin-bottom: 10px;">
            <span role="button" @click="applySavedSearch(search)">
              {{ search.title }}
            </span>
            </div>
          </v-col>
          <v-col @click.prevent="removeSearch(search.id)" class="icon-trash-highlight" v-show="search.id==cIndex" style="text-align: center" cols="1">
            <v-icon class="height-center">
              mdi-trash-can-outline
            </v-icon>
          </v-col>

        </v-row>


        <!--        <v-row class="tech_info" style="padding-bottom: 10px;">-->
        <!--          <v-col cols="auto" class="d-flex align-start">-->
        <!--            Saved: {{ article.article_details.created }}-->
        <!--          </v-col>-->
        <!--          <v-col cols="auto" class="d-flex align-start">-->
        <!--            Published: {{ article.article_details.created }}-->
        <!--          </v-col>-->
        <!--        </v-row>-->


      </v-col>
      <v-row v-if="totalPages > 1" class="mb-15 mb-md-5 mb-lg-5 mb-xl-5">
        <v-col cols="12" class="pl-10 pr-12 pr-md-5 ml-3 ">
          <v-pagination
              v-model="page"
              color="#EE7100"
              :light="true"
              class="my-4"
              :length="totalPages"
              @input="loadSearches()"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import router from "@/router";

export default {
  name: "SavedSearchTab",
  data: () => ({
    searches: null,
    page: 1,
    totalPages: 1,
    cIndex: -1,
  }),
  created() {
    this.loadSearches();
  },
  methods: {
    loadSearches() {
      this.$store.dispatch('overlay/show');
      this.$store.dispatch('search/getSavedSearch', this.page).then(
          response => {
            if (response.searches) {
              Object.keys(response.searches).forEach((key) => {
                response.searches[key].search_detail = JSON.parse(response.searches[key].search_detail);
              });

            }
            this.searches = response.searches;

            this.totalPages = response.totalPages;
            if (response.page > 1) {
              this.page = response.page;
            }
          },
          error => {
            if (error.response.status === 406) {
              localStorage.removeItem('user');
              router.push('/login');
              return;
            }
            if (error.response.status !== 403) {
              this.$notify({
                type: 'error',
                group: 'leita',
                duration: 10000,
                title: 'An error has occurred',
                text: 'Refresh the page and try again!'
              });
            }


          },

      ).finally(() => this.$store.dispatch('overlay/hide', "", {root: true}))
    },
    applySavedSearch(search) {
      const data = {
        "searchString": search.search_detail.searchString,
        'filter': search.search_detail.searchFilterForm,
      };

      this.$store.dispatch('search/startSearch', data);


    },
    async removeSearch(id) {
      let res = await this.$confirm('Do you really remove this item?', {title: 'Warning', color: '#EE7100', icon:''})
      if (res) {
        await this.$store.dispatch('overlay/show');

        this.$store.dispatch('search/removeSavedSearch', id).then(
            response => {

              if (response.result) {
                this.loadSearches();
              }
            },
            error => {
              console.log(error);
            }).finally(() => this.$store.dispatch('overlay/hide', "", {root: true}));

      }
    }
  }
}
</script>