<template>
  <div>
    <v-overlay :value="$store.state.overlay.show" :absolute="false">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-card flat>

      <v-col :key="article.id" v-for="article in saved_articles.articles" class="pa-0 pl-2" cols="auto">
        <v-row @mouseover="cIndex=article.id" @mouseleave="cIndex=-1" class="hover-tab-highlight">
          <v-col cols="11">
            <v-row class="article_header"
                   no-gutters
                   style="flex-wrap: nowrap;"
            >

              <div class="article_title justify-space-between" style="padding-top: 20px;">
                <a target="_blank" v-bind:href="'https://doi.org/'+ article.article_details.doi">
                  {{ article.article_details.title }}
                </a>
              </div>


            </v-row>

            <v-row style="margin-top: 0px; !important;" v-if="article.article_details.authors.length>0">
              <v-col style="padding-top: 0px;" cols="8" class="d-block text-truncate article_authors">
                {{ formatAuthors(article.article_details.authors) }}
              </v-col>
            </v-row>

            <v-row class="tech_info" style="padding-bottom: 10px; margin-top: 0px;">
              <v-col style="padding-top: 7px;" cols="auto" class="d-flex align-start">
                Published: {{ article.article_details.month }} {{ article.article_details.year }}
              </v-col>
              <v-col style="padding-top: 7px;" cols="auto" class="d-flex align-start">
                Saved: {{ article.article_details.created | formatOnlyData }}
              </v-col>
            </v-row>
          </v-col>
          <v-col @click.prevent="removeArticle(article.id)" v-show="article.id==cIndex" class="icon-trash-highlight" style="text-align: center; padding-left: 0px;" cols="1">
              <v-icon class="height-center">
                mdi-trash-can-outline
              </v-icon>
            </v-col>
        </v-row>
      </v-col>


      <v-row  class="pb-15 mb-md-5 mb-lg-5 mb-xl-5">
        <v-col v-if="totalPages > 1" cols="12" class="pl-10 pr-12 pr-md-5 ml-3 ">
          
         <v-pagination
              v-model="saved_articles.pagination.page"
              color="#EE7100"
              :light="true"
              class="my-4"
              :length="saved_articles.pagination.totalPages"
              @input="loadArticles()"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import PrepareAuthorsMixin from "@/mixins/PrepareAuthorsMixin";
import SaveArticleMixin from "@/mixins/SaveArticleMixin";

export default {
  name: "SavedArticlesTab",
  mixins: [PrepareAuthorsMixin, SaveArticleMixin],
  data: () => ({
    articles: null,
    page: 1,
    totalPages: 1,
    cIndex: -1,
  }),
  created() {
    this.loadArticles();
  },
  methods: {
    
  }
}
</script>