<template>
  <div style="padding-left: 1.5%">
    <v-row no-gutters style="padding-top: 20px;">

      <v-col cols="11" class="pl-2 pl-md-10 pl-xl-10 pl-lg-10">
        <span class="my-profile">My Dashboard</span>
      </v-col>

    </v-row>
    <v-row>
      <v-col cols="12" class="pl-md-10 pl-xl-10 pl-lg-10 pr-12 pr-md-5 ml-3 ">
        <v-divider style="border-color: #206297; border-width: 2px;"></v-divider>
      </v-col>

    </v-row>
    <v-row>
      <v-col class="pl-md-10 ml-md-3 pl-xl-10 ml-xl-3 pl-lg-10 ml-lg-3" cols="11" sm="8" md="8" lg="5" xl="5"
             style="padding: 0px;">
        <v-tabs
            v-model="tab"
            color="#EE7100"
            left
            slider-color="#EE7100"
            show-arrows
        >

          <v-tab class="tab-style" href="#tab-1">My Projects</v-tab>
          <v-tab class="tab-style" href="#tab-2">Saved Search</v-tab>
          <v-tab class="tab-style" href="#tab-3">Saved Articles</v-tab>
        </v-tabs>


      </v-col>


    </v-row>
    <v-row style="margin-top: 0px;">
      <v-col cols="12" class="pl-10 pr-12 pr-md-5 ml-3 ">
        <v-divider style="border-color: #C4C4C4; border-width: 1px;"></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pl-md-10 ml-md-3 pl-xl-10 ml-xl-3 pl-lg-10 ml-lg-3" cols="11" sm="8" md="8" lg="8" xl="8"
             style="padding: 0px;">
      <v-tabs-items v-model="tab">
        <v-tab-item
            key="1"
            value="tab-1"
            :transition=false
        >
          <div><h3>This function is under developing</h3></div>
        </v-tab-item>
          
        <v-tab-item
            key="2"
            value="tab-2"
            :transition=false
        >
          <SavedSearchTab></SavedSearchTab>

        </v-tab-item>

        <v-tab-item
            key="3"
            value="tab-3"
            :transition=false
        >
          <SavedArticlesTab></SavedArticlesTab>
        </v-tab-item>
      </v-tabs-items>
      </v-col>
    </v-row>





  </div>
</template>
<script>
import SavedArticlesTab from "@/components/dashboard/tabs/SavedArticlesTab";
import SavedSearchTab from "@/components/dashboard/tabs/SavedSearchTab";

export default {
  name: "Tabs",
  components:{
    SavedSearchTab,
    SavedArticlesTab
  },
  data(){
    return {
      tab: 'tab-2',
      page:1
    }
  }
}
</script>
<style>
.tab-style
{
  font-family: 'Gordita' !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 160% !important;
  /* identical to box height, or 34px */

  display: flex !important;
  align-items: center !important;
  letter-spacing: 0.03em !important;
}

.v-pagination__item{
  box-shadow:none !important;
}
.v-pagination__navigation{
  box-shadow:none !important;

}
</style>